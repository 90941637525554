<template>
  <el-dialog v-model="dialogVisible" title="條件搜尋" width="650px">
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      status-icon
      hide-required-asterisk
      label-position="top"
      class="search-form"
    >
      <el-form-item class="form-item">
        <template #label>
          <span class="label">帳號</span>
        </template>
        <el-input v-model="ruleForm.user_id" placeholder="請輸入帳號" />
      </el-form-item>
      <el-form-item class="form-item">
        <template #label>
          <span class="label">類別</span>
        </template>
        <el-select v-model="ruleForm.permission_id" placeholder="請選擇類別" clearable style="width: 100%">
          <el-option
            v-for="(option, index) in getPermissionList"
            :key="index"
            :label="option.permission_title"
            :value="option.permission_id + ' ' + option.permission_title"
          />
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" style="grid-area: 2/1/3/3">
        <template #label>
          <span class="label">可登入門市</span>
        </template>
        <el-cascader
          v-model="ruleForm.shop_id"
          :options="getStoreList"
          :show-all-levels="false"
          placeholder="請選擇或輸入門市名稱、代碼"
          style="width: 100%"
          clearable
          filterable
          collapse-tags
          collapse-tags-tooltip
          :props="{ emitPath: false, multiple: true }"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="close">取消</el-button>
        <el-button
          type="primary"
          @click="handleSearch(ruleFormRef)"
          :disabled="!checkSubmit()"
          style="margin-left: 25px"
        >
          搜尋
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import { inject, ref } from 'vue';
import { useGetters, useActions } from '@/utils/mapStore';
import { useRouter } from 'vue-router';
export default {
  name: 'account-dialog',
  setup() {
    const dialogVisible = inject('dialogVisible');
    const close = inject('close');
    const { cusProps } = inject('cusProps');
    //設定參數
    const ruleFormRef = ref(null);
    const ruleForm = ref(null);
    //設定初始值
    const getSearchCondition = useGetters('account', ['getSearchCondition']);
    if (dialogVisible) {
      if (getSearchCondition.value) ruleForm.value = JSON.parse(JSON.stringify(getSearchCondition.value));
      else
        ruleForm.value = {
          user_id: '',
          permission_id: '',
          shop_id: [],
        };
    }

    //選項-----------------------------------------------------
    const { getStoreList, getPermissionList } = useGetters('common', ['getStoreList', 'getPermissionList']);

    //驗證與檢查-----------------------------------------------------------------
    const checkSubmit = () => {
      let isValid;
      //未選擇任一條件
      isValid = !Object.keys(ruleForm.value).every((e) => !ruleForm.value[e] || ruleForm.value[e].length === 0);
      //未改變任一條件
      if (getSearchCondition.value && isValid) {
        isValid = !(JSON.stringify(ruleForm.value) === JSON.stringify(getSearchCondition.value));
      }
      return isValid;
    };

    //送出
    const router = useRouter();
    const doSearchUserList = useActions('account', ['doSearchUserList']);
    const handleSearch = async (form) => {
      try {
        if (!form) return;
        await form.validate();
        // console.log(ruleForm.value);
        await doSearchUserList({ start: 0, search: ruleForm.value, type: 'search' });
        router.push('/account/list/search?page=1');
        close();
      } catch (e) {
        e;
      }
    };
    return {
      dialogVisible,
      close,
      cusProps,
      ruleFormRef,
      ruleForm,
      getStoreList,
      getPermissionList,
      checkSubmit,
      handleSearch,
    };
  },
};
</script>
<style lang="scss" scoped>
.search-form {
  width: 100%;
  display: grid;
  grid-template-rows: 100px 1fr;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
}
.form-item {
  @include basic-form-item;
}
.dialog-footer {
  @include full-button;
}
</style>
